import React from 'react';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const ConsultTeam = (props) => {
    const img1 = getImage(props.data.dog2)
    const img2 = getImage(props.data.dog4)

    return (
        <div className="inner" style={{margin: '1.5rem 0', textAlign: 'center', lineHeight: 1}}>
            <ul className="actions team special fixed" style={{justifyContent:'space-evenly', flexWrap: 'wrap'}}>
                <li>
                    <GatsbyImage className="circle" alt="ANDRÉS JARAMILLO" image={img2} style={{width:'12em', height:'12em' , margin:0 }} />
                    <h2 className="label text-center">JUAN DIEGO MORENO</h2>
                    <p style={{margin: "0"}}>Comunicador Social</p>
                    <span style={{fontSize: "0.75rem"}}>Asesor en Branding /Diseño de Experiencia /Diseño de interacción /Diseño inclusivo</span>
                    <br />
                    <a href="https://www.linkedin.com/in/juan-diego-moreno-arango-42372533/" className="">
                        <StaticImage className="center-block" src="../images/LINKEDING.png" alt="LINKEDING logo" width={80}/>
                    </a>
                </li>   
                <li>
                    <GatsbyImage className="circle" alt="GERMÁN GONZALES" image={img1} style={{width:'12em', height:'12em' , margin:0}} />
                    <h2 className="label">GERMÁN GONZALEZ</h2>
                    <p style={{margin: "0"}}>Diseñador Industrial</p>
                    <span style={{fontSize: "0.75rem"}}>Especialista en Branding /Gerencia de marca /Consultoria</span>
                    <br />
                    <a href="https://www.linkedin.com/in/german-gonzalez-016540144/" className="">
                        <StaticImage className="center-block" src="../images/LINKEDING.png" alt="LINKEDING logo" width={80}/>
                    </a>
                </li>
    
            </ul>
        </div>

)}

export default React.memo(ConsultTeam);
