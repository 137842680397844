import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
class SeccionAbout extends React.Component {

   render() {
     const {children,team}= this.props; //id,seccionTitle,seccionSubTitle,description} = this.props;

     return (
        <React.Fragment>
            <div className="container">
                { team.map(
                    (t) => (            

                    <div key={t.id} id={t.id}  >      
                        <div id={t.id+"slide"} className="slide2">                        
                            <div className="text-center" style={{marginTop:'2em', marginBottom: '3em' , lineHeight: 1}}>
                                <h5 id={t.id+"Label"}> {t.description} </h5>
                                <br />                                
                                <h5 id={t.id+"Label2"}> {t.description2} </h5>
                            </div>                          

                            {children}

                            <h1 className="text-yellow"> {t.seccionSubTitle} </h1>

                            <div className="text-center" style= {{lineHeight: 1}}>
                                <h5 id={t.id+"Label3"}> {t.description3} </h5>
                                <br /> 
                                <h5 id={t.id+"Label4"}> {t.description4} </h5>                        

                                {(t.photo !== undefined ) ?  <GatsbyImage objectFit="contain" className="" alt="metodologia" image={t.photo} style={{marginTop:'2em', marginBottom: '3em' }} /> :<div></div>}
                               
                            </div>                           
            
                       
                        </div>
                    
                    </div>    
                    ) 
                )}
            </div>
        </React.Fragment>
         );
   }



}

export default React.memo(SeccionAbout);
