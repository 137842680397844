import { useContext } from 'react';
import { AccordionContext} from '../components/Layout';

export default function usePrenda({ pizzas, values }) {
  // 1. Create some state to hold our order
  // We got rid of this line because we moved useState up to the provider
  // const [order, setOrder] = useState([]);
  // Now we access both our state and our updater function (setOrder) via context
  const [order, setOrder] = useContext(AccordionContext);

  // 2. Make a function add things to order
  function addToOrder(refs) {
   // console.log('adding to cart', orderedPizza)
    setOrder([...refs]);
  }


  return {
    order,
    addToOrder,
  };
}
