import React from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";


const arrowStyles = { display: "flex", position:"absolute", top: "0", 
zIndex: "3" ,  fontSize:"2rem", height:"100%", width:"3rem", justifyContent: 'center',
alignItems: 'center'
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{...arrowStyles, right: 0}}
      onClick={onClick}>
        <span className= "icon fa-chevron-right text-yellow"></span>

      </div>

  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{...style, ...arrowStyles, left: 0}}
      onClick={onClick}
    >
      <span className="icon fa-chevron-left text-yellow"></span>
    </div>
  );
}

class Carousel extends React.Component {

constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.elements =  props.children;
}

  play() {
    this.slider.slickPlay();
  }

  pause() {
    this.slider.slickPause();
  }

  render() {
    const settings = {
      swipe: true,      
      variableWidth: true,   
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,      

      nextArrow: <SampleNextArrow  />,
      prevArrow: <SamplePrevArrow className="fa-chevron-right" />,

     autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,          
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
      const elements = this.elements; 
   
                  
    return (
      <div className="carousel-container1" 
        style={{
            width:"100%", maxHeight:"60vh",
            display: "block", overflowY  :'hidden' 
          }}>
        <Slider ref={slider => (this.slider = slider)} 
          {...settings} 
          className="carousel-items1"
          style={{
            width: "100%", maxHeight:"60vh",
            display: "block"   
          }}>
            {
                        elements.map((item, index) => {
                          return (             
                                  <div className="carousel-item1"  
                                       key={index}> 
                                      {item}
                                  </div>)
                        })
            }
        </Slider>
  
      </div>
    );
  }

}

export default Carousel
