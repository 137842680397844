import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import { Layout } from '../components/Layout'
import Banner from '../components/BannerZapp'
import About from '../components/SeccionAbout'
import Seccion from '../components/Seccion'
import Carousel from '../components/Carousel'
import Team from '../components/Team'
import ConsultTeam from '../components/ConsultTeam'
import Accordion from '../components/Accordion'
import usePrenda from '../utils/usePrenda'

import { getImage } from 'gatsby-plugin-image'

const Gallery = loadable(() => import('../components/Gallery'))

const SetMenu = ({ refs }) => {
  const { addToOrder } = usePrenda({})
  useEffect(() => {
    addToOrder(refs)
  }, [])

  return <></>
}

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrolled: false,
      timeout: false,
      isMenuOpen: false,
      albm: '',
    }

    this.bannerRef = React.createRef()
    this.aboutRef = React.createRef()
    this.brandingRef = React.createRef()
    this.interiorismoRef = React.createRef()
    this.productosRef = React.createRef()
    this.consultoriaRef = React.createRef()

    this.targetX = 0
    this.targetY = 0
  }

  render() {
    const { data } = this.props
    const about = [
      {
        id: 'about',
        seccionTitle: 'NUESTRO ESTUDIO',
        seccionSubTitle: 'METODOLOGÍA DE DISEÑO',
        description:
          'Como diseñadores es fácil ofrecer el conocimiento para replicar referentes estandarizados en la sociedad que ayudan a atender una cultura de consumo masivo que está impuesta, aún cuando este consumo nos resulte de gran atractivo no debemos olvidar quienes somos y de dónde venimos.',
        description2:
          'Por eso creamos Zapp, un estudio de diseño donde tomamos referentes de la cultura latinoamericana y la integramos en el desarrollo de nuestros proyectos en nuestras 3 líneas de diseño; gráfico, interiorismo y  productos.',
        description3:
          'Después de muchos años de investigación nos dimos cuenta que para lograr un buen proyecto de Diseño, es necesario generar un vinculo de confianza con los clientes y así poder comprender cuales son en realidad sus necesidades en temas de Diseño.',
        description4:
          'Nos dimos cuenta que los mejores proyectos han salido después de una buena conversación y una taza de café con nuestros clientes.',
        photo: getImage(data.metodologia), //data.metodologia.childImageSharp.fluid,
         cover: getImage(data.SECCION_NOSOTROS),
        estilo: {
          width: '100vw',
          height: '40vh',
          backgroundColor: 'gray',
          backgroundBlendMode: 'multiply',
          backgroundAttachment: 'fixed',
        },
      },
    ]

    const secciones = [
      [
        {
          id: 'BRANDING',
          seccionTitle: 'BRANDING',
          seccionSubTitle: 'Identidad de marca',
          description: '',
          cover: getImage(data.SECCION_BRANDING),
          estilo: {
            width: '100vw',
            height: '40vh',
            backgroundColor: 'gray',
            backgroundBlendMode: 'multiply',
            backgroundAttachment: 'fixed',
          },
        },
      ],
      [
        {
          id: 'INTERIORISMO',
          seccionTitle: 'INTERIORISMO',
          seccionSubTitle: 'Diseño de espacios',
          description: '',
          cover: getImage(data.SECCION_INTERIORISMO),
          estilo: {
            width: '100vw',
            height: '40vh',
            backgroundColor: 'gray',
            backgroundBlendMode: 'multiply',
            backgroundAttachment: 'fixed',
          },
        },
      ],
      [
        {
          id: 'PRODUCTOS',
          seccionTitle: 'PRODUCTOS',
          seccionSubTitle: 'Desarollo de productos',
          description: '',
          cover: getImage(data.SECCION_PRODUCTOS),
          estilo: {
            width: '100vw',
            height: '40vh',
            backgroundColor: 'gray',
            backgroundBlendMode: 'multiply',
            backgroundAttachment: 'fixed',
          },
        },
      ],
      [
        {
          id: 'CONSULTORIA',
          seccionTitle: 'CONSULTORÍA',
          seccionSubTitle: '',
          description: '',
          cover: getImage(data.SECCION_CONSULTORIA),
          estilo: {
            width: '100vw',
            height: '40vh',
            backgroundColor: 'gray',
            backgroundBlendMode: 'multiply',
            backgroundAttachment: 'fixed',
          },
        },
      ],
    ]

    return (
      <React.Fragment>
        <SetMenu
          refs={[
            this.aboutRef,
            this.brandingRef,
            this.interiorismoRef,
            this.productosRef,
            this.consultoriaRef,
          ]}
        />

        <Layout datos={data}>
          <Banner data={data} inputRef={this.bannerRef} />

          <Accordion ref={this.aboutRef}>
            <Seccion secciones={about} id="aboutStart" />

            <About id="about" data={data} team={about}>
              <h1 className="text-yellow"> QUIENES SOMOS </h1>
              <Team data={data} />
            </About>
          </Accordion>

          <Accordion ref={this.brandingRef}>
            <Seccion secciones={secciones[0]} id="servicios0" />

            <section
              id="BRANDING"
              className="tiles-no-space" /*onMouseMove={this.onHover}*/
            >
              <Gallery data={data} category={'BRANDING'} />
            </section>
          </Accordion>

          <Accordion ref={this.interiorismoRef}>
            <Seccion secciones={secciones[1]} id="servicios1" />
            <section
              id="INTERIORISMO"
              className="tiles-no-space" /*onMouseMove={this.onHover}*/
            >
              <Gallery data={data} category={'INTERIORISMO'} />
            </section>
          </Accordion>

          <Accordion ref={this.productosRef}>
            <Seccion secciones={secciones[2]} id="servicios2" />
            <section
              id="PRODUCTOS"
              className="tiles-no-space" /* onMouseMove={this.onHover}*/
            >
              <Gallery data={data} category={'PRODUCTOS'} />
            </section>
          </Accordion>
          <Accordion ref={this.consultoriaRef}>
            <Seccion secciones={secciones[3]} id="servicios3" />
            <section
              id="CONSULTORIA"
              className="" /* onMouseMove={this.onHover}*/
            >
              <ConsultTeam data={data} />
            </section>
          </Accordion>
        </Layout>
      </React.Fragment>
    )
  }
}

export default HomeIndex

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }

    DISENOLATINO: file(name: { eq: "diseno_latinoamericano" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 100
        )
      }
    }

    SECCION_BRANDING: file(name: { eq: "BRANDING" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1443
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FIXED
        )
      }
    }
    SECCION_INTERIORISMO: file(name: { eq: "INTERIORISMO" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1443
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FIXED
        )
      }
    }
    SECCION_PRODUCTOS: file(name: { eq: "PRODUCTOS" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          width: 1443
        )
      }
    }
    SECCION_CONSULTORIA: file(name: { eq: "23ago-178" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          width: 1443
        )
      }
    }
    SECCION_NOSOTROS: file(name: { eq: "SECCION_NOSOTROS" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1443
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FIXED
        )
      }
    }

    metodologia: file(name: { eq: "metodologia2" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1024
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    dog: file(name: { eq: "Dug-up" }) {
      childImageSharp {
        gatsbyImageData(
          width: 540
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dog1: file(name: { eq: "PP-01" }) {
      childImageSharp {
        gatsbyImageData(
          width: 380
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dog2: file(name: { eq: "german-01" }) {
      childImageSharp {
        gatsbyImageData(
          width: 380
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dog4: file(name: { eq: "JUAN_DIEGO" }) {
      childImageSharp {
        gatsbyImageData(
          width: 380
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    allProjectDataJson {
      nodes {
        album
        category
        cover
        description
        id
        seccionTitle
        jsonId
      }
    }
  }
`
