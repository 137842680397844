import React  from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

const scrollToGallery =  (id) => document.getElementById(id).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
class Seccion extends React.Component {
   render() {
     const {secciones, id} = this.props; //id,seccionTitle,seccionSubTitle,description} = this.props;

     return (

        <React.Fragment>
        <div className="container" id={id}>
            { secciones.map(
                (seccion, index) => (
                    <React.Fragment key={seccion.id + index} >
                
                        <div key={seccion.id}  style={seccion.estilo}  className="seccion-bg"
                             onClick={() => { /*scrollToGallery(seccion.id)*/ }}>      
                        <GatsbyImage image={seccion.cover}
                            alt={seccion.id}
                            className="tile"
                            objectFit="cover"
                            objectPosition="50% 50%"
                            style={{
                                objectFit: 'cover',
                                fontFamily: 'object-fit: cover',
                                height: '100%',
                                maxWidth: '100vw',
                                width: "auto",
                            }}

                        /> 
                            <div className="container-fluid bgsplash" style={{top:'-100%' , position:'relative', backgroundColor: '#21212199', backgroundBlendMode: 'multiply', backgroundAttachment: 'fixed'}} >
                                <h1 className="text-center seccionTitle"> {seccion.seccionTitle} </h1>
                                <div onClick={() => { scrollToGallery(seccion.id)}}>
                                    <i className="icon fa-chevron-down text-yellow "></i>
                                </div>
                            </div>                
                        </div>    
                </React.Fragment>
                ) 
            )}
        </div>
    </React.Fragment>
         );
   }
   /*
   

   */



}

export default React.memo(Seccion);
