import React from "react";

class Accordion extends React.Component {

constructor (props) {
  super(props);
  this.state = {
    setActive: ""
  }
}
  
  toggleAccordion() {    
    this.setState({ setActive : this.state.setActive === "" ? "roll" : "" });
  }

  render() {
    const { children } = this.props
    return (
      <div className="accordion__section">
        <div className={`accordion ${this.state.setActive}`} onClick={() => this.toggleAccordion()}>
          {children[0]}
        </div>      
        <div

          style={{display: this.state.setActive ? "block" : "none"  }}
          className="accordion__content"
        >
          {children[1]}
        </div>
      </div>
    );
  }

}

export default Accordion;