import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Team = (props) => {
    const img1 = getImage(props.data.dog2)
    const img2 = getImage(props.data.dog1)

    return (
        <div className="inner">
            <ul className="actions team special fixed" style={{justifyContent:'space-evenly', flexWrap: 'wrap', lineHeight: 1}}>
                <li>
                    <GatsbyImage className="circle" alt="GERMÁN GONZALEZ" image={img1} style={{width:'12em', height:'12em' , margin:0}} />
                    <a href="https://www.facebook.com/zappdiseno" className=""><h2 className="label" style={{margin: "0"}}>GERMÁN GONZALES</h2></a>
                    <p style={{fontSize: "0.75rem"}}>FUNDADOR Y DIRECTOR CREATIVO</p>
                </li>
                <li>
                    <GatsbyImage className="circle" alt="ANDRÉS JARAMILLO" image={img2} style={{width:'12em', height:'12em' , margin:0 }} />
                    <a href="https://www.instagram.com/zappdiseno/" className=""><h2 className="label" style={{margin: "0"}}>ANDRÉS JARAMILLO</h2></a>
                    <p style={{fontSize: "0.75rem"}}>COFUNDADOR</p>
                </li>
            </ul>
        </div>

)}

export default React.memo(Team);
