import React from 'react';

import { StaticImage } from "gatsby-plugin-image"

const BannerZapp = (props) => {
   
   return(
      <div style={{overflow:'hidden'}} className='hero-image'>
      {props.children}
            <div id="logo-container" className="jumbotron text-center container-fluid " >
               <StaticImage className="center-block fade-in-2" src="../images/LOGO.png" alt="zapp logo" loading="eager" placeholder='none' height={320} />
               {/*<StaticImage className="image center-block fade-in-2" src="../images/LOGO_ZAPP.png" alt="logo" loading="eager"/>
               <StaticImage className="image center-block fade-in-2" src="../images/BORO.png" alt="logo" loading="eager"/>*/}
            </div>
      </div>
)}

export default React.memo(BannerZapp);